import { filter } from 'lodash';
import { Chip, Stack } from '@mui/material';
import { LabelStyle, WrapperStyle } from '../styles/styles';
import { FormikPropsParticipantView, ParticipantFilter } from '../../../../@types/participant';

type ParticipantsListFiltersOverviewProps = {
  formik: FormikPropsParticipantView;
  filters: ParticipantFilter;
};

export default function ParticipantsFiltersOverview({
  formik,
  filters,
}: ParticipantsListFiltersOverviewProps) {
  const { handleSubmit, setFieldValue } = formik;
  const { status, eventType, level } = filters;

  const handleRemove = (field: 'status' | 'eventType' | 'level', value: string) => {
    const fieldValueMap = {
      status,
      eventType,
      level,
    };

    const newValue = filter(fieldValueMap[field], (_item) => _item !== value);
    handleSubmit();
    setFieldValue(field, newValue);
  };

  return (
    <>
      {!!eventType.length && (
        <WrapperStyle>
          <LabelStyle>event type:</LabelStyle>
          <Stack direction="row" flexWrap="nowrap" sx={{ p: 0.75 }}>
            {eventType.map((event) => (
              <Chip
                key={event}
                label={event}
                size="small"
                onDelete={() => handleRemove('eventType', event)}
                sx={{ m: 0.5 }}
              />
            ))}
          </Stack>
        </WrapperStyle>
      )}
      {!!level.length && (
        <WrapperStyle>
          <LabelStyle>level:</LabelStyle>
          <Stack direction="row" flexWrap="nowrap" sx={{ p: 0.75 }}>
            {level.map((level) => (
              <Chip
                key={level}
                label={level}
                size="small"
                onDelete={() => handleRemove('level', level)}
                sx={{ m: 0.5 }}
              />
            ))}
          </Stack>
        </WrapperStyle>
      )}
      {!!status.length && (
        <WrapperStyle>
          <LabelStyle>status:</LabelStyle>
          <Stack direction="row" flexWrap="nowrap" sx={{ p: 0.75 }}>
            {status.map((status) => (
              <Chip
                key={status}
                label={status}
                size="small"
                onDelete={() => handleRemove('status', status)}
                sx={{ m: 0.5 }}
              />
            ))}
          </Stack>
        </WrapperStyle>
      )}
    </>
  );
}
