import { useState, useEffect, SetStateAction, Dispatch } from 'react';
import { Card, Table, Container, TableContainer, SelectChangeEvent } from '@mui/material';
import ListHead from 'components/_dashboard/list/ListHead';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { PARTICIPANTS_LIST_TABLE_HEAD } from 'utils/constants/table-headings';
import TableFooter from 'components/_dashboard/list/TableFooter';
import { calculatePages } from 'utils/pages';
import { createRequestQuery } from 'utils/helperFiles';
import { initialPagination } from 'utils/constants/pages';
import Filters from 'components/_dashboard/filters/Filters';
import TableWrapper from 'components/_dashboard/table/TableWrapper';
import useSettings from 'hooks/useSettings';
import { GroupSortByQuery } from '../../../../@types/group';
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'routes/paths';
import ListToolbar from 'components/_dashboard/list/ListToolbar';
import Scrollbar from 'components/Scrollbar';
import { FilterType } from '../../../../@types/filters';
import { Participant } from '../../../../@types/participant';
import ParticipantTable from './ParticipantTable';
import axios from '../../../../utils/axios';
import { debounce } from 'lodash';
import { useLocation } from 'react-router';
import { parse } from 'querystring';
import { useParsedQuery } from 'hooks/useParsedQuery';

export default function ParticipantList() {
  const { themeStretch } = useSettings();
  const [participantsList, setParticipantsList] = useState([] as Participant[]);
  const [pagination, setPagination] = useState(initialPagination);
  const location = useLocation();
  const parsedQuery = parse(location.search);
  const parsedSearch = parsedQuery['?fuzzySearch'] as string;
  const { updateQuery } = useParsedQuery();
  const [searchQuery, setSearchQuery] = useState(parsedSearch ?? '');
  const isMountedRef = useIsMountedRef();
  const [sorting, setSorting] = useState({
    order: 'ascending' as 'ascending' | 'descending',
    orderBy: 'numberOfEntrants' as keyof GroupSortByQuery,
  });
  const [filters, setFilters] = useState({
    eventType: [] as string[],
    status: [] as string[],
    level: [] as string[],
  });
  const isDefaultFilter = Object.values(filters).every((filter) => !filter.length);

  useEffect(() => {
    if (isMountedRef.current) {
      (async () => {
        try {
          setPagination((prev) => ({ ...prev, loading: true }));
          const filtersQuery = createRequestQuery(
            {
              ...filters,
              page: pagination.page,
              limit: pagination.limit,
              fuzzySearch: searchQuery,
            },
            'filters'
          );

          const sortQuery = createRequestQuery(
            {
              [`${sorting.orderBy}` as keyof GroupSortByQuery]: [`${sorting.order}`],
            },
            'sortBy'
          );

          const requestQuery = (filtersQuery + sortQuery).slice(1);
          const response = await axios.get(
            `${window.BACK_OFFICE_CLIENT_ENV.API_BASE_URL}/back-office/participants?${requestQuery}`
          );
          setParticipantsList(response.data.data);
          setPagination(
            !response.data.pagination.totalItems
              ? { ...initialPagination, loading: false }
              : { ...response.data.pagination, loading: false }
          );
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [isMountedRef, pagination.page, pagination.limit, filters, sorting, searchQuery]);

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    const rows = event.target.value as number;
    const pageCount = calculatePages(rows, pagination);
    setPagination({ ...pagination, page: pageCount, limit: rows });
  };

  const handleSearchQuery = debounce((newSearchQuery: string) => {
    setPagination({ ...pagination, page: 1 });
    updateQuery({ fuzzySearch: newSearchQuery });
    setSearchQuery(newSearchQuery);
  }, 700);

  const handleRequestSort = (property: string) => {
    const isAsc = sorting.orderBy === property && sorting.order === 'ascending';
    setSorting({
      order: isAsc ? 'descending' : 'ascending',
      orderBy: property as keyof GroupSortByQuery,
    });
  };
  return (
    <Page title="Participants Search">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Participants Search"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Participants Search', href: PATH_DASHBOARD.groups.list },
          ]}
        />
        <Card>
          <ListToolbar
            searchQuery={searchQuery}
            onSearchQuery={handleSearchQuery}
            placeholder="Search participants..."
          >
            <Filters
              filters={filters}
              handleFilters={setFilters as Dispatch<SetStateAction<FilterType>>}
              setPagination={setPagination}
              filterType={'participant'}
            />
          </ListToolbar>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  headLabel={PARTICIPANTS_LIST_TABLE_HEAD}
                  order={sorting.order}
                  orderBy={sorting.orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableWrapper
                  loading={pagination.loading}
                  searchQuery={searchQuery}
                  listLength={!!participantsList?.length || false}
                  isDefaultFilter={isDefaultFilter}
                  colSpan={6}
                >
                  <ParticipantTable participantList={participantsList} />
                </TableWrapper>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TableFooter
            pagination={pagination}
            handleRows={handleChangeRowsPerPage}
            setPagination={setPagination}
          />
        </Card>
      </Container>
    </Page>
  );
}
