import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import { styled } from '@mui/material/styles';
import { Box, Toolbar, OutlinedInput, InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
  overflowX: 'auto',
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  minWidth: 240,
  width: '100%',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { minWidth: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

type ListToolbarProps = Readonly<{
  searchQuery: string;
  onSearchQuery: (value: string) => void;
  placeholder: string;
  children?: React.ReactChild;
}>;

export default function ListToolbar({
  searchQuery,
  onSearchQuery,
  placeholder,
  children,
}: ListToolbarProps) {
  const [inputValue, setInputValue] = useState(searchQuery);
  
    useEffect(() => {
      onSearchQuery(inputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);
  
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
    };

    return (
      <RootStyle>
        <SearchStyle
          value={inputValue}
          onChange={handleInputChange}
          placeholder={placeholder}
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
        {children}
      </RootStyle>
    );
}
