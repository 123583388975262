import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard, { AVALIABLE_ROLES as AVAILABLE_ROLES } from 'guards/RoleBasedGuard';
import GuestGuard from '../guards/GuestGuard';
import LoadingScreen from '../components/LoadingScreen';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import CustomerServiceSearch from 'pages/dashboard/customer-service/CustomerServiceSearch';
import StudioContextLayout from 'pages/dashboard/game-coordinator/StudioContextLayout';
import WDGiveawayForm from 'pages/dashboard/maintenance/whiteDiamondsGiveaway/WDGiveawayForm';
import CompetitionSearch from 'pages/dashboard/competition/competition-search/CompetitionSearch';
import ParticipantList from 'pages/dashboard/participant/participant-list/ParticipantList';

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const enableSchedules = useFeatureIsOn('enable-schedules');
  const defaultDashboardChildren = [
    { path: '', element: <Navigate to="/dashboard/app" replace /> },
    { path: 'app', element: <GeneralApp /> },
    {
      path: 'customer-service',
      element: (
        <RoleBasedGuard
          accessibleRoles={[AVAILABLE_ROLES.BO_ADMINS, AVAILABLE_ROLES.CUSTOMER_SERVICE]}
        />
      ),
      children: [
        { path: '', element: <Navigate to="/dashboard/customer-service/search" replace /> },
        {
          path: 'search',
          element: <CustomerServiceSearch />,
        },
      ],
    },
    {
      path: 'competitions',
      element: <RoleBasedGuard accessibleRoles={[AVAILABLE_ROLES.BO_ADMINS]} />,
      children: [
        { path: '', element: <Navigate to="/dashboard/competitions/search" replace /> },
        { path: 'search', element: <CompetitionSearch /> },
        { path: ':competitionId', element: <CompetitionDetails /> },
        { path: ':competitionId/participant/:participantId', element: <ParticipantPlayHistory /> },
      ],
    },
    {
      path: 'events',
      element: (
        <RoleBasedGuard
          accessibleRoles={[AVAILABLE_ROLES.BO_ADMINS, AVAILABLE_ROLES.SHOE_TECHNICIANS]}
        />
      ),
      children: [
        {
          path: '',
          element: <Navigate to="/dashboard/events/list" replace />,
        },
        { path: 'list', element: <EventsList /> },
        {
          path: ':id',
          element: <EventDetailsPage />,
        },
        {
          path: 'schedules',
          element: <RoleBasedGuard accessibleRoles={[AVAILABLE_ROLES.BO_ADMINS]} />,
          children: [
            { path: '', element: <Navigate to="/dashboard/events/schedules/list" replace /> },
            {
              path: 'list',
              element: enableSchedules ? (
                <SchedulesList />
              ) : (
                <>Schedules List Page is not available.</>
              ),
            },
          ],
        },
        {
          path: 'scheduler',
          element: <RoleBasedGuard accessibleRoles={[AVAILABLE_ROLES.BO_ADMINS]} />,
          children: [
            { path: '', element: <Navigate to="/dashboard/events/scheduler/new" replace /> },
            {
              path: 'new',
              element: enableSchedules ? <Scheduler /> : <>Scheduler Page is not available.</>,
            },
          ],
        },
      ],
    },
    {
      path: 'groups',
      element: <RoleBasedGuard accessibleRoles={[AVAILABLE_ROLES.BO_ADMINS]} />,
      children: [
        { path: '', element: <Navigate to="/dashboard/groups/list" replace /> },
        {
          path: 'list',
          element: <GroupList />,
        },
        {
          path: ':groupId/competitions',
          element: <CompetitionList />,
        },
        {
          path: ':groupId/competitions/create',
          element: <CompetitionCreate />,
        },
        {
          path: ':groupId/competitions/:competitionId',
          element: <CompetitionDetails />,
        },
        {
          path: ':groupId/competitions/:competitionId/participant/:participantId',
          element: <ParticipantPlayHistory />,
        },
        {
          path: 'participant-search',
          element: <ParticipantList />,
        },
        {
          path: 'participant-search/participant/:participantId',
          element: <ParticipantPlayHistory />,
        },
      ],
    },
    {
      path: 'templates',
      element: <RoleBasedGuard accessibleRoles={[AVAILABLE_ROLES.BO_ADMINS]} />,
      children: [
        { path: '', element: <Navigate to="/dashboard/templates/list" replace /> },
        { path: 'list', element: <TemplateList /> },
        {
          path: 'create',
          element: <TemplateForm />,
        },
        { path: 'update/:templateId', element: <TemplateForm /> },
      ],
    },
    {
      path: 'marketplace',
      element: <RoleBasedGuard accessibleRoles={[AVAILABLE_ROLES.BO_ADMINS]} />,
      children: [
        { path: '', element: <Navigate to="/dashboard/marketplace/list" replace /> },
        { path: 'list', element: <MarketplaceList /> },
        { path: ':marketplaceId', element: <MarketplaceForm /> },
        { path: 'create', element: <MarketplaceForm /> },
        { path: 'direct-bets-settings', element: <DirectBetsForm /> },
      ],
    },
    {
      path: 'maintenance',
      element: <RoleBasedGuard accessibleRoles={[AVAILABLE_ROLES.BO_ADMINS]} />,
      children: [
        { path: '', element: <Navigate to="/dashboard/maintenance/list" replace /> },
        { path: 'list', element: <MaintenanceList /> },
        { path: ':maintenanceId', element: <MaintenanceForm /> },
        { path: 'create', element: <MaintenanceForm /> },
        { path: 'white-diamonds-giveaway', element: <WDGiveawayForm /> },
      ],
    },
    {
      element: <StudioContextLayout />,
      children: [
        {
          path: 'card-reader-settings',
          element: (
            <RoleBasedGuard accessibleRoles={[AVAILABLE_ROLES.SHOE_TECHNICIANS]}>
              <CardReaderSettings />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'game-management',
          element: (
            <RoleBasedGuard accessibleRoles={[AVAILABLE_ROLES.SHOE_TECHNICIANS]}>
              <GameCoordinator />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'live-dealer-helper',
          element: enableSchedules ? (
            <RoleBasedGuard accessibleRoles={[AVAILABLE_ROLES.SHOE_TECHNICIANS]}>
              <LiveDealerHelperPage />
            </RoleBasedGuard>
          ) : (
            <>Live dealer helper page is not available.</>
          ),
        },
      ],
    },
  ];

  return useRoutes([
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <RoleBasedGuard
            accessibleRoles={[
              AVAILABLE_ROLES.BO_ADMINS,
              AVAILABLE_ROLES.SHOE_TECHNICIANS,
              AVAILABLE_ROLES.CUSTOMER_SERVICE,
            ]}
          >
            <DashboardLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: defaultDashboardChildren,
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
      children: [
        {
          path: 'components',
          children: [],
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

const EventsList = Loadable(lazy(() => import('../pages/dashboard/events/EventsList')));
const EventDetailsPage = Loadable(lazy(() => import('../pages/dashboard/events/EventDetailsPage')));
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/general/GeneralApp')));
const GroupList = Loadable(lazy(() => import('../pages/dashboard/group/GroupList')));
const TemplateList = Loadable(lazy(() => import('../pages/dashboard/template/TemplateList')));
const TemplateForm = Loadable(
  lazy(() => import('../pages/dashboard/template/template-form/TemplateForm'))
);
const CompetitionList = Loadable(
  lazy(() => import('../pages/dashboard/competition/competition-list/CompetitionList'))
);
const CompetitionCreate = Loadable(
  lazy(() => import('../pages/dashboard/competition/competition-create/CompetitionCreate'))
);

const CompetitionDetails = Loadable(
  lazy(() => import('../pages/dashboard/competition/competition-details/CompetitionDetails'))
);
const ParticipantPlayHistory = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/competition/competition-details/participants/play-history/ParticipantPlayHistory'
      )
  )
);
const MarketplaceList = Loadable(
  lazy(() => import('../pages/dashboard/marketplace/MarketplaceList'))
);
const MarketplaceForm = Loadable(
  lazy(() => import('../pages/dashboard/marketplace/MarketplaceForm'))
);

const MaintenanceList = Loadable(
  lazy(() => import('../pages/dashboard/maintenance/MaintenanceList'))
);

const MaintenanceForm = Loadable(
  lazy(() => import('../pages/dashboard/maintenance/MaintenanceForm'))
);

const SchedulesList = Loadable(lazy(() => import('../pages/dashboard/schedules/SchedulesList')));

const Scheduler = Loadable(lazy(() => import('../pages/dashboard/scheduler/Scheduler')));

const CardReaderSettings = Loadable(
  lazy(() => import('../pages/dashboard/cardReaderSettings/CardReaderSettings'))
);

const GameCoordinator = Loadable(
  lazy(() => import('../pages/dashboard/game-coordinator/GameCoordinator'))
);

const LiveDealerHelperPage = Loadable(
  lazy(() => import('../pages/dashboard/game-coordinator/LiveDealerHelperPage'))
);

const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

const DirectBetsForm = Loadable(
  lazy(() => import('../pages/dashboard/marketplace/direct-bets-settings/SettingsForm'))
);
