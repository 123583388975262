import { Stack, Checkbox, FormGroup, Typography, FormControlLabel } from '@mui/material';
import Scrollbar from '../../../Scrollbar';
import { FormikPropsParticipantView } from '../../../../@types/participant';
import {
  PARTICIPANT_EVENT_TYPE_FILTER,
  PARTICIPANT_LEVEL_FILTER,
  PARTICIPANT_STATUS_FILTER,
} from 'utils/constants/filters';

type ParticipantFiltersSidebarProps = Readonly<{
  formik: FormikPropsParticipantView;
}>;

export default function ParticipantFiltersSidebar({ formik }: ParticipantFiltersSidebarProps) {
  const { values, getFieldProps } = formik;

  return (
    <Scrollbar>
      <Stack spacing={3} sx={{ p: 3 }}>
        <div>
          <Typography variant="subtitle1" gutterBottom>
            Status
          </Typography>
          <FormGroup>
            {PARTICIPANT_STATUS_FILTER.map((item) => (
              <FormControlLabel
                key={item}
                control={
                  <Checkbox
                    {...getFieldProps('status')}
                    value={item}
                    checked={values.status.includes(item)}
                  />
                }
                label={item}
              />
            ))}
          </FormGroup>
        </div>
        <div>
          <Typography variant="subtitle1" gutterBottom>
            Level
          </Typography>
          <FormGroup>
            {PARTICIPANT_LEVEL_FILTER.map((item) => (
              <FormControlLabel
                key={item}
                control={
                  <Checkbox
                    {...getFieldProps('level')}
                    value={item}
                    checked={values.level.includes(item)}
                  />
                }
                label={item}
              />
            ))}
          </FormGroup>
        </div>
        <div>
          <Typography variant="subtitle1" gutterBottom>
            Event Type
          </Typography>
          <FormGroup>
            {PARTICIPANT_EVENT_TYPE_FILTER.map((item) => (
              <FormControlLabel
                key={item}
                control={
                  <Checkbox
                    {...getFieldProps('eventType')}
                    value={item}
                    checked={values.eventType.includes(item)}
                  />
                }
                label={item}
              />
            ))}
          </FormGroup>
        </div>
      </Stack>
    </Scrollbar>
  );
}
