import React, { useState } from 'react';
import { Button, Typography, Box } from '@mui/material';
import { CardReaderUI } from '../../../../@types/cardReader';
import { useGameCoordinatorContext } from '../GameCoordinatorStateProvider';
import { SIGNAL_TYPES } from '../types';
import { CardData, ranks, suits } from '../../../../@types/game';
import { cardDataCodeToGSSString, getValueForRank } from '../../../../utils/cards';

const shuffleArray = (array: any[]) => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

const newShoe = (): CardData[] =>
  Array.from({ length: 8 }, () =>
    Object.values(suits).flatMap((suit) =>
      ranks.map((rank) => {
        return {
          code: `${suit.suit}${rank}`,
          suit,
          rank,
          label: `${rank}${suit.symbol}`,
          value: getValueForRank(rank),
        };
      })
    )
  ).flat();

const CardReaderEmulatorUI: CardReaderUI = () => {
  const { processing, signalProcessor } = useGameCoordinatorContext();
  const [shuffledDeck, setShuffledDeck] = useState<CardData[]>(shuffleArray(newShoe()));
  const [drawnCards, setDrawnCards] = useState<CardData[]>([]);
  const [showDeck, setShowDeck] = useState(false);

  const drawCard = () => {
    if (shuffledDeck.length > 0) {
      const drawnCard = shuffledDeck.pop();
      if (drawnCard) {
        setDrawnCards((prev) => [...prev, drawnCard]);
        signalProcessor({
          type: SIGNAL_TYPES.NEW_CARD,
          value: cardDataCodeToGSSString(drawnCard.code),
        });
      }
    }
  };

  const resetDeck = () => {
    setShuffledDeck(shuffleArray(newShoe()));
    setDrawnCards([]);
  };

  const nextCard = shuffledDeck[shuffledDeck.length - 1];
  const handleCardClick = (card: CardData) => {
    setShuffledDeck((prevDeck) => {
      const newDeck = [...prevDeck];
      const cardIndex = newDeck.findIndex((c) => c.code === card.code);
      if (cardIndex !== -1) {
        const [selectedCard] = newDeck.splice(cardIndex, 1);
        newDeck.push(selectedCard);
      }
      return newDeck;
    });
    setShowDeck(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      border="1px solid "
      width="100%"
      overflow="auto"
    >
      <Typography mb={1} variant="h6">
        Card Reader Emulator
      </Typography>
      <Box display="flex" gap={2}>
        <Button
          sx={{ margin: '20px 0', display: 'block' }}
          variant="contained"
          color="primary"
          onClick={() => setShowDeck(true)}
          disabled={processing}
        >
          <div
            style={{
              flex: '0 0 auto',
              width: '30px',
              height: '40px',
              border: '1px solid #ccc',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '18px',
              fontWeight: 'bold',
              margin: '3px',
              color: nextCard.suit.color,
              backgroundColor: 'white',
            }}
          >
            {nextCard.label}
          </div>
        </Button>
        <Button
          sx={{ margin: '20px 0', display: 'block' }}
          variant="contained"
          color="success"
          onClick={drawCard}
          disabled={processing}
        >
          Draw Card
        </Button>
        <Button
          sx={{ margin: '20px 0', display: 'block' }}
          variant="contained"
          color="warning"
          onClick={resetDeck}
        >
          Reset and Shuffle
        </Button>
      </Box>

      <Box
        width="100%"
        minHeight="150px"
        maxHeight="300px"
        border="1px solid "
        overflow="auto"
        p={1}
      >
        <h3>Cards Remaining: {shuffledDeck.length}</h3>
        <h3>Drawn Cards:</h3>
        <ul
          style={{
            listStyle: 'none',
            padding: 0,
            margin: 0,
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          {drawnCards.map((card, index) => (
            <li
              key={index}
              style={{
                flex: '0 0 auto',
                width: '30px',
                height: '40px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '18px',
                fontWeight: 'bold',
                margin: '3px',
                color: card.suit.color,
                backgroundColor: 'white',
              }}
            >
              {card.label}
            </li>
          ))}
        </ul>
      </Box>

      {showDeck && (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="rgba(0, 0, 0, 0.5)"
          zIndex="1000"
        >
          <Box width="80%" maxHeight="80%" bgcolor="white" borderRadius="8px" p={2} overflow="auto">
            <Typography variant="h6" mb={2}>
              Select a Card
            </Typography>
            <Box display="flex" flexWrap="wrap">
              {shuffledDeck.map((card, index) => (
                <Box
                  key={index}
                  flex="0 0 auto"
                  width="30px"
                  height="40px"
                  border="1px solid #ccc"
                  borderRadius="8px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize="18px"
                  fontWeight="bold"
                  margin="3px"
                  color={card.suit.color}
                  bgcolor="white"
                  onClick={() => handleCardClick(card)}
                  sx={{ cursor: 'pointer' }}
                >
                  {card.label}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CardReaderEmulatorUI;
