import { parse, stringify } from "query-string";
import { useLocation, useNavigate } from "react-router-dom";

export const useParsedQuery = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const parsedQuery = Array.from(params.keys()).reduce((acc, key) => {
    const values = params.getAll(key);
    acc[key] = values;
    return acc;
  }, {} as Record<string, string[]>);
  
  const updateQuery = (newParams: Record<string, string | string[]>) => {
    const existingParams = parse(location.search);
    const updatedParams = { ...existingParams, ...newParams };

    // Remove empty string params
    Object.keys(updatedParams).forEach(key => {
      if (updatedParams[key] === '') {
        delete updatedParams[key];
      }
    });

    const newQueryString = stringify(updatedParams, { skipNull: true });
    navigate(`${location.pathname}?${newQueryString}`);
  };

  return {parsedQuery, updateQuery};
};
