import { filter } from "lodash";
import { Chip, Stack } from "@mui/material";
import {
  CompetitionSearchFilter,
  FormikPropsCompetitionSearchView,
} from "../../../../@types/competition";
import { LabelStyle, WrapperStyle } from "../styles/styles";

type CompetitionSearchFiltersOverviewProps = Readonly<{
  formik: FormikPropsCompetitionSearchView;
  filters: CompetitionSearchFilter;
}>;

export default function CompetitionSearchFiltersOverview({
  formik,
  filters,
}: CompetitionSearchFiltersOverviewProps) {
  const { handleSubmit, setFieldValue } = formik;
  const { status, eventType, level } = filters;

  const handleRemoveStatus = (value: string) => {
    const newValue = filter(status, (_item) => _item !== value);
    handleSubmit();
    setFieldValue("status", newValue);
  };

  const handleRemoveEventType = (value: string) => {
    const newValue = filter(eventType, (_item) => _item !== value);
    handleSubmit();
    setFieldValue("eventType", newValue);
  };

  const handleRemoveLevel = (value: string) => {
    const newValue = filter(level, (_item) => _item !== value);
    handleSubmit();
    setFieldValue("level", newValue);
  };

  return (
    <>
      {!!status.length && (
        <WrapperStyle>
          <LabelStyle>Status:</LabelStyle>
          <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
            {status.map((_status) => (
              <Chip
                key={_status}
                label={_status}
                size="small"
                onDelete={() => handleRemoveStatus(_status)}
                sx={{ m: 0.5 }}
              />
            ))}
          </Stack>
        </WrapperStyle>
      )}
      {!!eventType.length && (
        <WrapperStyle>
          <LabelStyle>Event type:</LabelStyle>
          <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
            {eventType.map((type) => (
              <Chip
                key={type}
                label={type}
                size="small"
                onDelete={() => handleRemoveEventType(type)}
                sx={{ m: 0.5 }}
              />
            ))}
          </Stack>
        </WrapperStyle>
      )}
      {!!level.length && (
        <WrapperStyle>
          <LabelStyle>Level number:</LabelStyle>
          <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
            {level.map((type) => (
              <Chip
                key={type}
                label={type}
                size="small"
                onDelete={() => handleRemoveLevel(type)}
                sx={{ m: 0.5 }}
              />
            ))}
          </Stack>
        </WrapperStyle>
      )}
    </>
  );
}
