import { Icon } from '@iconify/react';
import { Form, FormikProvider } from 'formik';
import closeFill from '@iconify/icons-eva/close-fill';
import roundClearAll from '@iconify/icons-ic/round-clear-all';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import { Box, Stack, Button, Drawer, Divider, Typography } from '@mui/material';
import { MIconButton } from '../../../@material-extend';
import {
  FormikPropsCompetitionSearchView,
  FormikPropsCompetitionView,
} from '../../../../@types/competition';
import { FormikPropsGroupView } from '../../../../@types/group';
import { FormikPropsMarketplaceView } from '../../../../@types/marketplace';
import CompetitionFiltersSidebar from './CompetitionFiltersSidebar';
import GroupFiltersSidebar from './GroupFiltersSidebar';
import MarketplaceFiltersSidebar from './MarketplaceFiltersSidebar';
import { FormikFilterProps } from '../../../../@types/filters';
import MaintenanceEventsSidebar from './MaintenanceEventsSidebar';
import { FormikPropsMaintenanceView } from '../../../../@types/maintenance';
import { FormikPropsCustomerServiceSearchView } from '../../../../@types/customerServiceSearch';
import CustomerServiceSearchFiltersSidebar from './CustomerServiceSearchFiltersSidebar';
import CompetitionSearchFilterSidebar from './CompetitionSearchFilterSidebar';
import { FormikPropsParticipantView } from '../../../../@types/participant';
import ParticipantFiltersSidebar from './ParticipantFiltersSidebar';

type FilterSidebarProps = {
  onResetFilter: VoidFunction;
  onOpenFilter: VoidFunction;
  onCloseFilter: VoidFunction;
  filterType: string;
  isOpenFilter: boolean;
  formik: FormikFilterProps;
  marketplacesData?: { name: string; value: string }[];
};

export default function FiltersSidebar({
  onResetFilter,
  onOpenFilter,
  onCloseFilter,
  filterType,
  isOpenFilter,
  formik,
  marketplacesData,
}: FilterSidebarProps) {
  return (
    <>
      <Button
        disableRipple
        color="inherit"
        endIcon={<Icon icon={roundFilterList} />}
        onClick={onOpenFilter}
      >
        Filters&nbsp;
      </Button>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate>
          <Drawer
            anchor="right"
            open={isOpenFilter}
            onClose={onCloseFilter}
            PaperProps={{
              sx: { width: 280, border: 'none', overflow: 'hidden' },
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ px: 1, py: 2 }}
            >
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                Filters
              </Typography>
              <MIconButton onClick={onCloseFilter}>
                <Icon icon={closeFill} width={20} height={20} />
              </MIconButton>
            </Stack>

            <Divider />
            {filterType === 'competition' && (
              <CompetitionFiltersSidebar formik={formik as FormikPropsCompetitionView} />
            )}
            {filterType === 'group' && (
              <GroupFiltersSidebar formik={formik as FormikPropsGroupView} />
            )}
            {filterType === 'marketplace' && (
              <MarketplaceFiltersSidebar formik={formik as FormikPropsMarketplaceView} />
            )}
            {filterType === 'maintenance' && (
              <MaintenanceEventsSidebar
                formik={formik as FormikPropsMaintenanceView}
                marketplacesData={marketplacesData || []}
              />
            )}
            {filterType === 'customerService' && (
              <CustomerServiceSearchFiltersSidebar
                formik={formik as FormikPropsCustomerServiceSearchView}
              />
            )}

            {filterType === 'participant' && (
              <ParticipantFiltersSidebar formik={formik as FormikPropsParticipantView} />
            )}
            {filterType === 'competitionSearch' && (
              <CompetitionSearchFilterSidebar formik={formik as FormikPropsCompetitionSearchView} />
            )}

            <Box sx={{ p: 3 }}>
              <Button
                fullWidth
                size="large"
                type="submit"
                color="inherit"
                variant="outlined"
                onClick={onResetFilter}
                startIcon={<Icon icon={roundClearAll} />}
              >
                Clear All
              </Button>
            </Box>
          </Drawer>
        </Form>
      </FormikProvider>
    </>
  );
}
