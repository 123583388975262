import React from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { FullCompetition } from '../../../../@types/competition';
import { useNavigate } from 'react-router-dom';

interface CompetitionSearchTableProps {
  competitions: FullCompetition[];
}

const CompetitionSearchTable: React.FC<CompetitionSearchTableProps> = ({ competitions }) => {
  const navigate = useNavigate();
  const handleRowClick = (id: string) => {
    navigate(`/dashboard/competitions/${id}`);
  }

  return (
        <TableBody>
          {competitions?.map((competition) => (
            <TableRow 
              key={competition._id} 
              onClick={() => handleRowClick(competition._id)} 
              style={{ cursor: 'pointer' }}
            >
              <TableCell>{competition._id}</TableCell>
              <TableCell>{competition.ls3ParentEventId}</TableCell>
              <TableCell>{competition.eventType}</TableCell>
              <TableCell>{competition.type}</TableCell>
              <TableCell>{competition.status}</TableCell>
              <TableCell>{competition.startTime}</TableCell>
              <TableCell>{competition.endTime}</TableCell>
            </TableRow>
          ))}
        </TableBody>
  );
};

export default CompetitionSearchTable;