import { TableRow, TableBody, TableCell } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Participant } from '../../../../@types/participant';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  clickable: {
    cursor: 'pointer',
  },
}));

interface ParticipantTableProps {
  participantList: Participant[];
}

export default function ParticipantTable({ participantList }: ParticipantTableProps) {
  const classes = useStyles();
  const navigate = useNavigate();

  const getParticipant = async (id: string) => {
    navigate(`/dashboard/groups/participant-search/participant/${id}`);
  };

  return (
    <TableBody>
      {participantList?.map((participant) => {
        return (
          <TableRow
            hover
            key={participant?._id}
            tabIndex={-1}
            onClick={() => getParticipant(participant?._id)}
            className={classes.clickable}
          >
            <TableCell align="left">{participant?._id || '-'}</TableCell>
            <TableCell align="left">{participant?.eventId || '-'}</TableCell>
            <TableCell align="left">{participant?.competitionId || '-'}</TableCell>
            <TableCell align="left">{participant?.eventType || '-'}</TableCell>
            <TableCell align="left">{participant?.competitionInfo?.eventType || '-'}</TableCell>
            <TableCell align="left">{participant?.status || '-'}</TableCell>
            <TableCell align="left">{participant?.numberOfPlays || '-'}</TableCell>
            <TableCell align="left">{participant?.entryFee || '-'}</TableCell>
            <TableCell align="left">{participant?.wonAmount || '-'}</TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
