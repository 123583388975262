import { Icon } from '@iconify/react';
import roundClearAll from '@iconify/icons-ic/round-clear-all';
import { Button } from '@mui/material';
import {
  CompetitionFilter,
  CompetitionSearchFilter,
  FormikPropsCompetitionSearchView,
  FormikPropsCompetitionView,
} from '../../../../@types/competition';
import { FormikPropsGroupView, GroupFilter } from '../../../../@types/group';
import { FormikPropsMarketplaceView, MarketplaceFilter } from '../../../../@types/marketplace';
import CompetitionFiltersOverview from './CompetitionFilteresOverview';
import { RootStyle } from '../styles/styles';
import GroupFiltersOverview from './GroupFiltersOverview';
import MarketplaceFiltersOverview from './MarketplaceFiltersOverview';
import { FilterType, FormikFilterProps } from '../../../../@types/filters';
import MaintenanceEventsOverview from './MaintenanceEventsOverview';
import { FormikPropsMaintenanceView, MaintenanceFilter } from '../../../../@types/maintenance';
import {
  CustomerServiceSearchFilter,
  FormikPropsCustomerServiceSearchView,
} from '../../../../@types/customerServiceSearch';
import CustomerServiceSearchFiltersOverview from './CustomerServiceSearchFiltersOverview';
import CompetitionSearchFiltersOverview from './CompetitionSearchFIltersOverview';
import ParticipantsFiltersOverview from './ParticipantsFiltersOverview';
import { FormikPropsParticipantView, ParticipantFilter } from '../../../../@types/participant';

type SelectedFiltersOverviewProps = {
  formik: FormikFilterProps;
  filters: FilterType;
  isShowReset: boolean;
  filterType: string;
  onResetFilter: VoidFunction;
  marketplacesData?: { name: string; value: string }[];
};

export default function SelectedFiltersOverview({
  formik,
  filters,
  isShowReset,
  filterType,
  onResetFilter,
  marketplacesData,
}: SelectedFiltersOverviewProps) {
  const { dirty } = formik;

  return (
    <RootStyle sx={{ flexWrap: 'nowrap' }}>
      {filterType === 'competition' && (
        <CompetitionFiltersOverview
          formik={formik as FormikPropsCompetitionView}
          filters={filters as CompetitionFilter}
        />
      )}
      {filterType === 'group' && (
        <GroupFiltersOverview
          formik={formik as FormikPropsGroupView}
          filters={filters as GroupFilter}
        />
      )}
      {filterType === 'marketplace' && (
        <MarketplaceFiltersOverview
          formik={formik as FormikPropsMarketplaceView}
          filters={filters as MarketplaceFilter}
        />
      )}
      {filterType === 'maintenance' && (
        <MaintenanceEventsOverview
          formik={formik as FormikPropsMaintenanceView}
          filters={filters as MaintenanceFilter}
          marketplacesData={marketplacesData || []}
        />
      )}
      {filterType === 'customerService' && (
        <CustomerServiceSearchFiltersOverview
          formik={formik as FormikPropsCustomerServiceSearchView}
          filters={filters as CustomerServiceSearchFilter}
        />
      )}
      {filterType === 'competitionSearch' && (
        <CompetitionSearchFiltersOverview
          formik={formik as FormikPropsCompetitionSearchView}
          filters={filters as CompetitionSearchFilter}
        />
      )}
      {filterType === 'participant' && (
        <ParticipantsFiltersOverview
          formik={formik as FormikPropsParticipantView}
          filters={filters as ParticipantFilter}
        />
      )}
      {dirty && !isShowReset && (
        <Button
          color="error"
          size="small"
          type="button"
          onClick={onResetFilter}
          startIcon={<Icon icon={roundClearAll} />}
        >
          Clear All
        </Button>
      )}
    </RootStyle>
  );
}
