import Auth from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';

export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;

export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;

export const configureAmplify = () => {
  const awsConfig: any = {
    aws_appsync_graphqlEndpoint:
      window.BACK_OFFICE_CLIENT_ENV?.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
    aws_appsync_region: window.BACK_OFFICE_CLIENT_ENV?.REACT_APP_AWS_PROJECT_REGION,
    aws_appsync_authenticationType:
      window.BACK_OFFICE_CLIENT_ENV?.REACT_APP_AWS_APPSYNC_AUTHENTIFICATION_TYPE,
    Auth: {
      region: window.BACK_OFFICE_CLIENT_ENV?.REACT_APP_AWS_PROJECT_REGION || 'eu-west-2',
      userPoolId: window.BACK_OFFICE_CLIENT_ENV?.COGNITO_USER_POOL_ID,
      userPoolWebClientId: window.BACK_OFFICE_CLIENT_ENV?.COGNITO_CLIENT_ID,
    },
    API: {
      graphql_headers: async () => {
        const session = await Auth.currentSession();
        return {
          Authorization: session.getIdToken().getJwtToken(),
        };
      },
    },
  };

  Amplify.configure(awsConfig);
  Auth.configure();
};
