import { Stack, Checkbox, FormGroup, Typography, FormControlLabel } from '@mui/material';
import Scrollbar from '../../../Scrollbar';
import { FormikPropsCompetitionSearchView } from '../../../../@types/competition';
import {
  COMPETITION_STATUS_FILTER,
  COMPETITION_LEVEL_FILTER,
  EVENT_TYPE_FILTER,
} from 'utils/constants/filters';

type CompetitionSearchFiltersSidebarProps = Readonly<{
  formik: FormikPropsCompetitionSearchView;
}>;

export default function CompetiltersSidebar({ formik }: CompetitionSearchFiltersSidebarProps) {
  const { values, getFieldProps } = formik;

  return (
    <Scrollbar>
      <Stack spacing={3} sx={{ p: 3 }}>
        <div>
          <Typography variant="subtitle1" gutterBottom>
            Event Type
          </Typography>
          <FormGroup>
            {EVENT_TYPE_FILTER.map((item) => (
              <FormControlLabel
                key={item}
                control={
                  <Checkbox
                    {...getFieldProps('eventType')}
                    value={item}
                    checked={values?.eventType.includes(item)}
                  />
                }
                label={item}
              />
            ))}
          </FormGroup>
        </div>
        <div>
          <Typography variant="subtitle1" gutterBottom>
            Status
          </Typography>
          <FormGroup>
            {COMPETITION_STATUS_FILTER.map((item) => (
              <FormControlLabel
                key={item}
                control={
                  <Checkbox
                    {...getFieldProps('status')}
                    value={item}
                    checked={values?.status.includes(item)}
                  />
                }
                label={item}
              />
            ))}
          </FormGroup>
        </div>

        <div>
          <Typography variant="subtitle1" gutterBottom>
            Level
          </Typography>
          <FormGroup>
            {COMPETITION_LEVEL_FILTER.map((item) => (
              <FormControlLabel
                key={item.value}
                control={
                  <Checkbox
                    {...getFieldProps('level')}
                    value={item.value}
                    checked={values?.level.includes(item.value)}
                  />
                }
                label={item.label}
              />
            ))}
          </FormGroup>
        </div>
      </Stack>
    </Scrollbar>
  );
}
