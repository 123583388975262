import { Stack } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Pagination } from '../../../@types/pagination';
import { FILTERS_INITIAL_VALUES } from 'utils/constants/filters';
import SelectedFiltersOverview from './selectedFiltersOverview/SelectedFiltersOveriew';
import FiltersSidebar from './filtersSidebar/FiltersSidebar';
import { FilterType, FormikFilterProps } from '../../../@types/filters';
import { useNavigate } from 'react-router-dom';
import { stringify } from 'query-string';
import { useParsedQuery } from 'hooks/useParsedQuery';

type FiltersProps = {
  filters: FilterType;
  filterType: string;
  handleFilters: Dispatch<SetStateAction<FilterType>>;
  setPagination: Dispatch<SetStateAction<Pagination>>;
  marketplacesData?: { name: string; value: string }[];
};
export default function CompetitionListFilters({
  filters,
  filterType,
  handleFilters,
  setPagination,
  marketplacesData,
}: FiltersProps) {
  const [openFilter, setOpenFilter] = useState(false);
  const navigate = useNavigate();
  const { parsedQuery } = useParsedQuery();

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const formik = useFormik<FilterType>({
    initialValues: {
      ...FILTERS_INITIAL_VALUES[
        filterType as
          | 'competition'
          | 'group'
          | 'marketplace'
          | 'maintenance'
          | 'customerService'
          | 'competitionSearch'
          | 'participant'
      ],
      ...parsedQuery,
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  const { values, resetForm, handleSubmit } = formik;

  useEffect(() => {
    handleFilters(values);
    const query = stringify(values, {
      skipNull: true,
    });
    navigate({ search: query }); // keep the filter query in the URL
    setPagination((prev) => {
      return { ...prev, page: 1 };
    });
  }, [values, handleFilters, setPagination, navigate]);

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };

  return (
    <>
      <SelectedFiltersOverview
        filters={filters}
        formik={formik as FormikFilterProps}
        isShowReset={openFilter}
        filterType={filterType}
        onResetFilter={handleResetFilter}
        marketplacesData={marketplacesData}
      />
      <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end">
        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <FiltersSidebar
            formik={formik as FormikFilterProps}
            isOpenFilter={openFilter}
            onResetFilter={handleResetFilter}
            onOpenFilter={handleOpenFilter}
            onCloseFilter={handleCloseFilter}
            filterType={filterType}
            marketplacesData={marketplacesData}
          />
        </Stack>
      </Stack>
    </>
  );
}
