function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,

  // UNUSED ROUTES
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey'),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
  },

  // USED ROUTES
  groups: {
    root: path(ROOTS_DASHBOARD, '/groups'),
    list: path(ROOTS_DASHBOARD, '/groups/list'),
    competitions: path(ROOTS_DASHBOARD, '/groups/:groupId/competitions'),
    newCompetition: path(ROOTS_DASHBOARD, '/groups/:groupId/competitions/create'),
    competitionDetails: path(ROOTS_DASHBOARD, '/groups/:groupId/competitions/:competitionId'),
    participantPlayHistory: path(
      ROOTS_DASHBOARD,
      '/groups/:groupId/competitions/:competitionId/participant/:participantId'
    ),
    participantSearch: path(ROOTS_DASHBOARD, '/groups/participant-search'),
  },
  events: {
    root: path(ROOTS_DASHBOARD, '/events'),
    list: path(ROOTS_DASHBOARD, '/events/list'),
    eventDetails: path(ROOTS_DASHBOARD, '/events/:id'),
    schedules: path(ROOTS_DASHBOARD, '/events/schedules'),
    schedulesList: path(ROOTS_DASHBOARD, '/events/schedules/list'),
    newSchedule: path(ROOTS_DASHBOARD, '/events/schedules/create'),
    scheduler: path(ROOTS_DASHBOARD, '/events/scheduler'),
  },
  competitions: {
    root: path(ROOTS_DASHBOARD, '/competitions'),
  },
  templates: {
    root: path(ROOTS_DASHBOARD, '/templates'),
    list: path(ROOTS_DASHBOARD, '/templates/list'),
    newTemplate: path(ROOTS_DASHBOARD, '/templates/create'),
    update: path(ROOTS_DASHBOARD, '/templates/update/:templateId'),
  },
  marketplace: {
    root: path(ROOTS_DASHBOARD, '/marketplace'),
    list: path(ROOTS_DASHBOARD, '/marketplace/list'),
    marketplaceDetails: path(ROOTS_DASHBOARD, '/marketplace/:marketplaceId'),
    newMarketplace: path(ROOTS_DASHBOARD, '/marketplace/create'),
    directBetsFSettings: path(ROOTS_DASHBOARD, '/marketplace/direct-bets-settings'),
  },
  maintenance: {
    root: path(ROOTS_DASHBOARD, '/maintenance'),
    list: path(ROOTS_DASHBOARD, '/maintenance/list'),
    maintenanceDetails: path(ROOTS_DASHBOARD, '/maintenance/:maintenanceId'),
    newMaintenance: path(ROOTS_DASHBOARD, '/maintenance/create'),
    whiteDiamondsGiveaway: path(ROOTS_DASHBOARD, '/maintenance/white-diamonds-giveaway'),
  },
  shoe: {
    root: path(ROOTS_DASHBOARD, '/shoe'),
  },
  gameManagement: {
    root: path(ROOTS_DASHBOARD, '/game-management'),
    gameCoordinator: path(ROOTS_DASHBOARD, '/game-management/game-coordinator'),
    liveDealerHelper: path(ROOTS_DASHBOARD, '/live-dealer-helper'),
    cardReaderSettings: path(ROOTS_DASHBOARD, '/card-reader-settings'),
  },
  customerService: {
    root: path(ROOTS_DASHBOARD, '/customer-service'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
